import { HttpEventType } from '@angular/common/http';
import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { AppService } from 'src/app/app.service';
import { CommonHttpService } from 'src/app/common-http.service';
import { Subscription } from 'rxjs';
import { MessageService } from 'primeng/api';
import { getExtension } from 'src/app/common/common';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

export interface fileProgress {
  selectedFile: string;
  file: File;
  progress: number;
  uploaded: boolean;
  loaded: number;
  isRequestActive: boolean;
  uploadingError: boolean;
  isCompressed: boolean;
  isCompressing: boolean;
  selected: boolean;
  validationError: boolean;
  error: boolean;
  errorMessage: string;
}
@Component({
  selector: 'app-file-uploader',
  templateUrl: './file-uploader.component.html',
  styleUrls: ['../../shared/css/common-style.component.scss', './file-uploader.component.scss'],
})
export class FileUploaderComponent implements OnInit, OnDestroy {
  @ViewChild('fileDropRef', { static: false }) fileDropEl: ElementRef | undefined;
  @Input() files: any[] = [];
  @Input() location: string = '';
  /**
   * size in Byte eg. 1KB == 1024 Byte
   * 100KB * 1024 = 102400
  */
  @Input() maxFileSize: number = 102400;
  @Input() allowedExtension: string[] = [".png", ".jpg", ".jpeg", ".webp", ".avif"];
  @Output('fileUpdate') fileUpdate = new EventEmitter();
  selectedFiles: fileProgress[] = [];
  innerwidth: number = window.innerWidth;
  isSidebarOpen: boolean = false;
  barIconOpenSub!: Subscription;
  isUploading: boolean = false;
  selectAllFiles: boolean = false;
  relativeIconPath: any = {
    ".xlsx": "../../../assets/xlsx.png",
    ".docx": "../../../assets/docx.png",
    ".csv": "../../../assets/csv.png",
    ".pdf": "../../../assets/pdf.png"
  }

  get isCompressing(): boolean {
    return Boolean(this.selectedFiles.find((item: fileProgress) => item.isCompressing));
  }

  get getSelectedFiles(): fileProgress[] {
    this.selectedFiles = this.selectedFiles.filter((item: fileProgress) => !item.uploaded);
    return this.selectedFiles;
  }

  isImage(name: string): boolean {
    return [".png", ".jpg", ".jpeg", ".webp", ".avif"].includes(getExtension(name));
  }

  constructor(
    private readonly commonHttpService: CommonHttpService,
    private readonly appService: AppService,
    private messageService: MessageService
  ) { }

  ngOnInit(): void {
    this.barIconOpenSub = this.appService.isBarIconOpen.subscribe({
      next: (response) => {
        this.isSidebarOpen = response;
      },
      error: (error) => {
        console.log('error ', error);
      }
    })
  }

  onFileDropped(event: any[]) {
    if (event.length <= 0) return;
    this.prepareFilesList(event);
  }

  fileBrowseHandler(event: any) {
    if (event?.target) {
      this.prepareFilesList(event.target.files);
    }
  }

  private prepareFilesList(files: Array<File>): void {
    for (const item of files) {
      let ext = getExtension(item.name);
      if (!this.allowedExtension.includes(ext)) {
        this.messageService.add({ 
          severity: 'error',
          summary: `Validation Error`,
          detail: `${ext} Invalid file extension: Please upload an image with a valid extension(e.g. ${this.allowedExtension.join(' ,')})`,
        });
        continue;
      }

      const selectedFile = {
        selectedFile: URL.createObjectURL(item),
        file: item,
        progress: 0,
        uploaded: false,
        loaded: 0,
        isRequestActive: false,
        uploadingError: false,
        isCompressed: false,
        isCompressing: false,
        selected: false,
        validationError: false,
        error: false,
        errorMessage: '',
      }

      this.selectedFiles = [selectedFile, ...this.selectedFiles];
      this.selectAllFiles = false;
    }

    if (this.fileDropEl) {
      this.fileDropEl.nativeElement.value = '';
    }
  }

  //File uploading
  private uploadFile(item: fileProgress): Promise<void> {
    this.isUploading = true;
    let location = this.location;
    const ext = getExtension(item.file.name);
    if(this.relativeIconPath[ext]) {
      location += `/${ext.replace('.', '')}`
    }
    const promise = new Promise<void>((resolve, reject) => {
      this.commonHttpService.fileUploader(item.file, location).subscribe({
        next: (event: any) => {
          item.isRequestActive = true;
          if (event.type === HttpEventType.UploadProgress) {
            item.progress = +Math.round((event.loaded / event.total) * 100)
            item.loaded = event.loaded;
            this.isUploading = true;
          }

          if (event.type === HttpEventType.Response) {
            this.files.push(
              event.body[0].imagePath
            );

            item.uploaded = true;
            this.isUploading = false;
            item.selected = false;
            this.fileUpdate.emit(this.files);
          }

          resolve();
        },
        error: (error: any) => {
          item.uploadingError = true;
        }
      });
    })

    return promise;
  }

  onClickUpload(): void {
    this.selectedFiles.forEach(async (item: fileProgress, idx: number) => {
      if (!item.uploaded && !item.isRequestActive && item.isCompressed) {
        this.uploadFile(item);
      }
    });
  }

  onClickCompressor(): void {
    let availableForCompress = 0;
    const allCompressedFiles = this.selectedFiles.filter((item: fileProgress) => item.isCompressed);
    const notCompressedFiles = this.selectedFiles.filter((item: fileProgress) => !item.isCompressed && item.selected);
    this.selectedFiles.forEach((item: fileProgress) => !item.selected && !item.isCompressed && ++availableForCompress);

    if (allCompressedFiles.length === this.selectedFiles.length && !this.isUploading) {
      this.messageService.add({
        severity: 'success',
        summary: `Ready To Upload`,
        detail: `All files have been compressed and are ready for upload.`,
      });
    }

    if (notCompressedFiles.length <= 0 && availableForCompress > 0) {
      this.messageService.add({
        severity: 'error',
        summary: `File Not Selected`,
        detail: `Please select the file you want to compress.`,
      });
      return;
    }

    this.selectedFiles.forEach((item: fileProgress, idx: number) => {
      const fileName = item.file.name;
      if (item.isCompressed || !item.selected || item.isCompressing || !this.isImage(fileName)) {
        item.selected = false;
        return
      };
      this.imageConvertor(item, fileName)
    })
  }

  onSelected(index: number): void {
    const selectedFile = this.selectedFiles[index];
    selectedFile.selected = !selectedFile.selected;
    const flag = this.selectedFiles.find((item: fileProgress) => !item.selected);
    if (!flag && !this.selectAllFiles) {
      this.selectAllFiles = true;
    }
    else if (flag && this.selectAllFiles) {
      this.selectAllFiles = false;
    }
  }

  convertKbToMb(size: number): string {
    let kb = size / 1024;
    let mb = kb / 1024;
    let data = '';
    if (kb < 1024) {
      data = kb.toFixed(2) + "KB";
    } else if (kb >= 1024 && mb <= 1024) {
      data = mb.toFixed(2) + 'MB';
    }

    return data;
  }

  splitFileName(fileName: string): string {
    let len = 160;
    let sideBarHeight = this.isSidebarOpen ? 200 : 80;
    let width = this.innerwidth - sideBarHeight - 64 - 150 - 64 - 100;
    if (this.innerwidth <= 500) {
      width += 220
    }
    else if (this.innerwidth <= 650) {
      width += 110;
    }

    len = width / 7.56;
    let splitArray = fileName?.split('.');
    let modifiedStr = fileName;
    if (fileName?.length > len + splitArray[1].length) {
      modifiedStr = splitArray[0].substring(0, len) + '...' + splitArray[1];
    }

    return modifiedStr;
  }

  deleteImage(index: number) {
    this.files.splice(index, 1);
    this.fileUpdate.emit(this.files);
  }

  onChangeCheckbox(): void {
    if (this.selectAllFiles) {
      this.selectedFiles.forEach((item: fileProgress) => {
        item.selected = true;
      })
    } else {
      this.selectedFiles.forEach((item: fileProgress) => {
        item.selected = false;
      })
    }
  }

  onClickDelete(): void {
    this.selectedFiles = this.selectedFiles.filter((item: fileProgress) => !item.selected);
  }

  toggleCheckBox(): void {
    this.selectAllFiles = !this.selectAllFiles;
    this.onChangeCheckbox();
  }

  getFileUrl(file: fileProgress): string {
    const ext: string = getExtension(file.file.name); 
    return this.relativeIconPath[ext] ||  '../../../assets/image.png';
  }

  getUploadedFileUrl(name: string): string {
    const ext = getExtension(name);
    return this.relativeIconPath[ext];
  }

  @HostListener('window:resize', ['$event'])
  onResizeWindow(event: any): void {
    this.innerwidth = window.innerWidth;
  }

  onDropDay(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.files, event.previousIndex, event.currentIndex);
    console.log('files ', this.files)
    this.fileUpdate.emit(this.files)
  }

  async imageConvertor(item: fileProgress, fileName: string): Promise<void> {
    item.isCompressing = true;
    item.file = await this.convertToWebp(item.file, fileName);
    item.isCompressed = true;
    item.isCompressing = false;
    item.selected = false;
  }

  convertToWebp(file: File, fileName: string): Promise<File> {
    const promise = new Promise<File>((resolve, reject) => {
      if(!file) reject(null);
      const reader = new FileReader();
      const canvas = document.createElement('canvas')
      reader.onload = (e) => {
        const processedImageDataUrl = reader.result as string;
        const img = new Image();
        img.onload = () => {
          const ctx = canvas.getContext('2d');
          if(ctx) {
            const maxWidth = 1600;
            const maxHeight = 800;
            let width = img.width;
            let height = img.height;
            if (width > maxWidth || height > maxHeight) {
              width = maxWidth;
              height = maxHeight;
            }
    
            canvas.width = width;
            canvas.height = height;
    
            ctx.drawImage(img, 0, 0, width, height);
            const processedImageDataUrl = canvas.toDataURL('image/webp');
            fileName = fileName.replace(/\.[^/.]+$/, "") + '.webp'
            const file = this.dataURLToFile(processedImageDataUrl, fileName);
            resolve(file)
          }
        };
        img.src = processedImageDataUrl;
      };
      reader.readAsDataURL(file);
    })

    return promise;
  }


  dataURLToFile(dataURL: string, fileName: string): File {
    const parts = dataURL.split(';base64,');
    const contentType = parts[0].split(':')[1];
    const raw = window.atob(parts[1]);
    const rawLength = raw.length;
    const uint8Array = new Uint8Array(rawLength);

    for (let i = 0; i < rawLength; ++i) {
      uint8Array[i] = raw.charCodeAt(i);
    }

    const blob =  new Blob([uint8Array], { type: contentType });
    return new File([blob], fileName, { type: blob.type });
  }

  ngOnDestroy(): void {
    if (this.barIconOpenSub) this.barIconOpenSub.unsubscribe();
  }
}







// import { HttpEventType } from '@angular/common/http';
// import {
//   Component,
//   ElementRef,
//   EventEmitter,
//   HostListener,
//   Input,
//   OnDestroy,
//   OnInit,
//   Output,
//   ViewChild,
// } from '@angular/core';
// import { AppService } from 'src/app/app.service';
// import { CommonHttpService } from 'src/app/common-http.service';
// import { Subscription } from 'rxjs';
// import { NgxImageCompressService } from 'ngx-image-compress';
// import { MessageService } from 'primeng/api';
// import { getExtension } from 'src/app/common/common';
// import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

// export interface fileProgress {
//   selectedFile: string;
//   file: File;
//   progress: number;
//   uploaded: boolean;
//   loaded: number;
//   isRequestActive: boolean;
//   uploadingError: boolean;
//   isCompressed: boolean;
//   isCompressing: boolean;
//   selected: boolean;
//   validationError: boolean;
//   error: boolean;
//   errorMessage: string;
// }
// @Component({
//   selector: 'app-file-uploader',
//   templateUrl: './file-uploader.component.html',
//   styleUrls: ['../../shared/css/common-style.component.scss', './file-uploader.component.scss'],
// })
// export class FileUploaderComponent implements OnInit, OnDestroy {
//   @ViewChild('fileDropRef', { static: false }) fileDropEl: ElementRef | undefined;
//   @Input() files: any[] = [];
//   @Input() location: string = '';
//   /**
//    * size in Byte eg. 1KB == 1024 Byte
//    * 100KB * 1024 = 102400
//   */
//   @Input() maxFileSize: number = 102400;
//   @Input() allowedExtension: string[] = [".png", ".jpg", ".jpeg", ".webp"];
//   @Output('fileUpdate') fileUpdate = new EventEmitter();
//   selectedFiles: fileProgress[] = [];
//   innerwidth: number = window.innerWidth;
//   isSidebarOpen: boolean = false;
//   barIconOpenSub!: Subscription;
//   isUploading: boolean = false;
//   selectAllFiles: boolean = false;
//   relativeIconPath: any = {
//     ".xlsx": "../../../assets/xlsx.png",
//     ".docx": "../../../assets/docx.png",
//     ".csv": "../../../assets/csv.png",
//     ".pdf": "../../../assets/pdf.png"
//   }

//   get isCompressing(): boolean {
//     return Boolean(this.selectedFiles.find((item: fileProgress) => item.isCompressing));
//   }

//   get getSelectedFiles(): fileProgress[] {
//     this.selectedFiles = this.selectedFiles.filter((item: fileProgress) => !item.uploaded);
//     return this.selectedFiles;
//   }

//   isImage(name: string): boolean {
//     return [".png", ".jpg", ".jpeg", ".webp"].includes(getExtension(name));
//   }

//   constructor(
//     private readonly commonHttpService: CommonHttpService,
//     private readonly appService: AppService,
//     private imageCompress: NgxImageCompressService,
//     private messageService: MessageService
//   ) { }

//   ngOnInit(): void {
//     this.barIconOpenSub = this.appService.isBarIconOpen.subscribe({
//       next: (response) => {
//         this.isSidebarOpen = response;
//       },
//       error: (error) => {
//         console.log('error ', error);
//       }
//     })
//   }

//   onFileDropped(event: any[]) {
//     if (event.length <= 0) return;
//     this.prepareFilesList(event);
//   }

//   fileBrowseHandler(event: any) {
//     if (event?.target) {
//       this.prepareFilesList(event.target.files);
//     }
//   }

//   private prepareFilesList(files: Array<File>): void {
//     for (const item of files) {
//       let ext = getExtension(item.name);
//       if (!this.allowedExtension.includes(ext)) {
//         this.messageService.add({ 
//           severity: 'error',
//           summary: `Validation Error`,
//           detail: `${ext} Invalid file extension: Please upload an image with a valid extension(e.g. ${this.allowedExtension.join(' ,')})`,
//         });
//         continue;
//       }

//       const selectedFile = {
//         selectedFile: URL.createObjectURL(item),
//         file: item,
//         progress: 0,
//         uploaded: false,
//         loaded: 0,
//         isRequestActive: false,
//         uploadingError: false,
//         isCompressed: false,
//         isCompressing: false,
//         selected: false,
//         validationError: false,
//         error: false,
//         errorMessage: '',
//       }

//       this.selectedFiles = [selectedFile, ...this.selectedFiles];
//       this.selectAllFiles = false;
//     }

//     if (this.fileDropEl) {
//       this.fileDropEl.nativeElement.value = '';
//     }
//   }

//   //File uploading
//   private uploadFile(item: fileProgress): Promise<void> {
//     this.isUploading = true;
//     let location = this.location;
//     const ext = getExtension(item.file.name);
//     if(this.relativeIconPath[ext]) {
//       location += `/${ext.replace('.', '')}`
//     }
//     const promise = new Promise<void>((resolve, reject) => {
//       this.commonHttpService.fileUploader(item.file, location).subscribe({
//         next: (event: any) => {
//           item.isRequestActive = true;
//           if (event.type === HttpEventType.UploadProgress) {
//             item.progress = +Math.round((event.loaded / event.total) * 100)
//             item.loaded = event.loaded;
//             this.isUploading = true;
//           }

//           if (event.type === HttpEventType.Response) {
//             this.files.push(
//               event.body[0].imagePath
//             );

//             item.uploaded = true;
//             this.isUploading = false;
//             item.selected = false;
//             this.fileUpdate.emit(this.files);
//           }

//           resolve();
//         },
//         error: (error: any) => {
//           item.uploadingError = true;
//         }
//       });
//     })

//     return promise;
//   }

//   onClickUpload(): void {
//     this.selectedFiles.forEach(async (item: fileProgress, idx: number) => {
//       if (item.file.size >= this.maxFileSize) {
//         item.error = true;
//         item.validationError = true;
//         item.errorMessage = `Image size exceeds the allowed limit of ${this.convertKbToMb(this.maxFileSize)}. Please choose a smaller image file or compress the current image to reduce its size.`
//         return;
//       }
//       else {
//         item.error = false;
//         item.validationError = false;
//         item.errorMessage = ``
//       }

//       if (!item.uploaded && !item.isRequestActive) {
//         this.uploadFile(item);
//       }
//     });
//   }

//   // compressor
//   fileToURL(file: File): Promise<string> {
//     const promise = new Promise<string>((resolve, reject) => {
//       const reader = new FileReader();
//       reader.readAsDataURL(file);

//       reader.onload = () => {
//         const dataUrl = reader.result as string;
//         resolve(dataUrl);
//       };

//       reader.onerror = (error) => {
//         reject(error);
//       };
//     });

//     return promise;
//   }

//   dataURItoFile(dataURI: string, fileName: string): File {
//     const byteString = atob(dataURI.split(',')[1]);
//     const ab = new ArrayBuffer(byteString.length);
//     const ia = new Uint8Array(ab);
//     for (let i = 0; i < byteString.length; i++) {
//       ia[i] = byteString.charCodeAt(i);
//     }
//     const blob = new Blob([ab], { type: 'image/jpeg' });
//     const file = new File([blob], fileName, { type: blob.type });
//     return file;
//   }

//   private compressFile(file: string): Promise<string> {
//     const promise = new Promise<string>((resolve, reject) => {
//       this.imageCompress.compressFile(file, -1, 50, 50).then(
//         (result: string) => {
//           resolve(result);
//         }
//       ).catch((error: any) => {
//         reject(error);
//       });
//     });
//     return promise;
//   }

//   async compressedFile(file: File): Promise<File> {
//     const fileURL = await this.fileToURL(file);
//     const compressedFileURL = await this.compressFile(fileURL)
//     return this.dataURItoFile(compressedFileURL, file.name);
//   }

//   async fileCompressor(item: fileProgress, idx: number): Promise<void> {
//     item.isCompressing = true;
//     let file = new File([item.file], item.file.name, { type: item.file.type });
//     let size = item.file.size;
//     while (size >= this.maxFileSize) {
//       file = await this.compressedFile(file);
//       size = file.size;
//     }
//     item.file = file;
//     item.isCompressed = true;
//     item.selected = false;
//     this.selectAllFiles = false;
//     item.isCompressing = false;
//   }

//   onClickCompressor(): void {
//     let availableForCompress = 0;
//     const allCompressedFiles = this.selectedFiles.filter((item: fileProgress) => item.isCompressed);
//     const notCompressedFiles = this.selectedFiles.filter((item: fileProgress) => !item.isCompressed && item.selected);
//     this.selectedFiles.forEach((item: fileProgress) => !item.selected && !item.isCompressed && ++availableForCompress);

//     if (allCompressedFiles.length === this.selectedFiles.length && !this.isUploading) {
//       this.messageService.add({
//         severity: 'success',
//         summary: `Ready To Upload`,
//         detail: `All files have been compressed and are ready for upload.`,
//       });
//       return;
//     }

//     if (notCompressedFiles.length <= 0 && availableForCompress > 0) {
//       this.messageService.add({
//         severity: 'error',
//         summary: `File Not Selected`,
//         detail: `Please select the file you want to compress.`,
//       });
//       return;
//     }

//     this.selectedFiles.forEach((item: fileProgress, idx: number) => {
//       const fileName = item.file.name;
//       if (item.file.size <= this.maxFileSize || item.isCompressed || !item.selected || item.isCompressing || !this.isImage(fileName)) {
//         item.selected = false;
//         return
//       };
//       this.fileCompressor(item, idx);
//     })

//   }

//   onSelected(index: number): void {
//     const selectedFile = this.selectedFiles[index];
//     selectedFile.selected = !selectedFile.selected;
//     const flag = this.selectedFiles.find((item: fileProgress) => !item.selected);
//     if (!flag && !this.selectAllFiles) {
//       this.selectAllFiles = true;
//     }
//     else if (flag && this.selectAllFiles) {
//       this.selectAllFiles = false;
//     }
//   }

//   convertKbToMb(size: number): string {
//     let kb = size / 1024;
//     let mb = kb / 1024;
//     let data = '';
//     if (kb < 1024) {
//       data = kb.toFixed(2) + "KB";
//     } else if (kb >= 1024 && mb <= 1024) {
//       data = mb.toFixed(2) + 'MB';
//     }

//     return data;
//   }

//   splitFileName(fileName: string): string {
//     let len = 160;
//     let sideBarHeight = this.isSidebarOpen ? 200 : 80;
//     let width = this.innerwidth - sideBarHeight - 64 - 150 - 64 - 100;
//     if (this.innerwidth <= 500) {
//       width += 220
//     }
//     else if (this.innerwidth <= 650) {
//       width += 110;
//     }

//     len = width / 7.56;
//     let splitArray = fileName?.split('.');
//     let modifiedStr = fileName;
//     if (fileName?.length > len + splitArray[1].length) {
//       modifiedStr = splitArray[0].substring(0, len) + '...' + splitArray[1];
//     }

//     return modifiedStr;
//   }

//   deleteImage(index: number) {
//     this.files.splice(index, 1);
//     this.fileUpdate.emit(this.files);
//   }

//   onChangeCheckbox(): void {
//     if (this.selectAllFiles) {
//       this.selectedFiles.forEach((item: fileProgress) => {
//         item.selected = true;
//       })
//     } else {
//       this.selectedFiles.forEach((item: fileProgress) => {
//         item.selected = false;
//       })
//     }
//   }

//   onClickDelete(): void {
//     this.selectedFiles = this.selectedFiles.filter((item: fileProgress) => !item.selected);
//   }

//   toggleCheckBox(): void {
//     this.selectAllFiles = !this.selectAllFiles;
//     this.onChangeCheckbox();
//   }

//   getFileUrl(file: fileProgress): string {
//     const ext: string = getExtension(file.file.name); 
//     return this.relativeIconPath[ext] ||  '../../../assets/image.png';
//   }

//   getUploadedFileUrl(name: string): string {
//     const ext = getExtension(name);
//     return this.relativeIconPath[ext];
//   }

//   @HostListener('window:resize', ['$event'])
//   onResizeWindow(event: any): void {
//     this.innerwidth = window.innerWidth;
//   }

//   onDropDay(event: CdkDragDrop<string[]>) {
//     moveItemInArray(this.files, event.previousIndex, event.currentIndex);
//     console.log('files ', this.files)
//     this.fileUpdate.emit(this.files)
//   }


//   ngOnDestroy(): void {
//     if (this.barIconOpenSub) this.barIconOpenSub.unsubscribe();
//   }
// }
